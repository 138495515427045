// eslint-disable-next-line canonical/filename-match-regex
import { type Ref, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import {
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
    trackEecPromotionClick,
    trackEecPromotionImpression,
} from '@jsmdg/tracking';
import { Banner, ResponsiveFrame, useImpressionCallback, Wysiwyg } from '@jsmdg/yoshi';
import { type Creative } from '../../../shared/types';
import { CreativeName, PromotionType } from '../../enums/trackingPromotions';
import styles from './ACMBanner.module.scss';

const messages = defineMessages({
    day: {
        defaultMessage: 'Tag',
    },
    days: {
        defaultMessage: 'Tagen',
    },
    hour: {
        defaultMessage: 'Stunde',
    },
    hours: {
        defaultMessage: 'Stunden',
    },
    minute: {
        defaultMessage: 'Minute',
    },
    minutes: {
        defaultMessage: 'Minuten',
    },
    seconds: {
        defaultMessage: 'Sekunden',
    },
});

export const ACMBanner = ({
    asterisk,
    campaignName,
    countdown,
    image,
    placeholderId,
    trackingName,
    url,
}: Creative): JSX.Element => {
    const intl = useIntl();

    const name = campaignName;
    const id = trackingName;
    const position = 'vertical1_horizontal1';

    const { body, iconColor, title } = asterisk || {};

    const data = {
        promotionTrackingPayload: {
            id,
            name,
            creative: `${placeholderId}_CategoryLanding`,
            position,
        },
        promotionTrackingEventData: {
            creativeName: CreativeName.NavigationBanner,
            creativeSlot: position,
            promotionName: name,
            promotionId: id,
            locationId: url || '',
            promotionCountdown: countdown ? 'true' : 'false',
            promotionPlaceholder: placeholderId,
            promotionType: PromotionType.Dynamic,
        },
    };

    const trackProductImpression = useCallback(() => {
        trackEecPromotionImpression(
            [data.promotionTrackingPayload],

            data.promotionTrackingEventData,
        );
    }, [data.promotionTrackingEventData, data.promotionTrackingPayload]);

    const setImpressionTrackedElement = useImpressionCallback(trackProductImpression);

    return (
        <ResponsiveFrame
            width={250}
            height={120}
            className={classNames('mb-2x', styles.bannerLink)}
        >
            <Banner
                skeletonClasses={styles.skeleton}
                imageUrl={image.url}
                alt={image.alt}
                link={url}
                lazyLoad // so that mobile browsers don't load this.
                className="position-absolute"
                fullWidth
                text="Endet in"
                msCountdown={
                    countdown ? new Date(countdown.endDateUtc).getTime() - Date.now() : undefined
                }
                countdownUnits={{
                    days: {
                        single: intl.formatMessage(messages.day),
                        plural: intl.formatMessage(messages.days),
                    },
                    hours: {
                        single: intl.formatMessage(messages.hour),
                        plural: intl.formatMessage(messages.hours),
                    },
                    minutes: {
                        single: intl.formatMessage(messages.minute),
                        plural: intl.formatMessage(messages.minutes),
                    },
                    seconds: {
                        plural: intl.formatMessage(messages.seconds),
                    },
                }}
                textColor={countdown?.color as string}
                modalInfos={
                    body
                        ? {
                              headline: <Wysiwyg content={title || ''} />,
                              infoContent: <Wysiwyg content={body || ''} />,
                              infoIconColor: iconColor || '',
                              a11yCloseText: title || '',
                          }
                        : undefined
                }
                ref={setImpressionTrackedElement as Ref<HTMLDivElement>}
                onClick={() => {
                    trackEecPromotionClick(
                        data.promotionTrackingPayload,
                        data.promotionTrackingEventData,
                    );
                }}
                onInfoClick={() =>
                    trackAnalyticsEvent({
                        eventData: {
                            eventName: GA4EventName.ClickButton,
                            feature_category: GA4FeatureCategory.PromotionDynamic,
                            creative_name: 'Navigation-banner',
                            click_element: 'Info icon',
                        },
                    })
                }
            />
        </ResponsiveFrame>
    );
};
